import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import { PAGES, BASE_URL } from 'config';
import { Layout } from 'containers';


const ThankYouPage = () => (
  <Layout page="itinerary-thank-you">

    <Helmet
      title="Thanks for Downloading Our New Zealand Brochure | Haka Tours, NZ"
      meta={[
        {
          name: 'description', content: 'Thanks for requesting a copy of the Haka Tours brochure. It will be sent to your email immediately so that you can plan and your build your NZ adventure.',
        },
        { name: 'og:title', content: 'Thanks for Downloading Our New Zealand Brochure | Haka Tours, NZ' },
        {
          name: 'og:description', content: 'Thanks for requesting a copy of the Haka Tours brochure. It will be sent to your email immediately so that you can plan and your build your NZ adventure.',
        },
        { name: 'robots', content: 'noindex' },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/thank-you/` }]}
    />

    <section className="l-section">
      <div className="l-container l-container--small u-text--center">
        <h1 className="c-heading c-heading--h1">Thanks!</h1>
        <p>
          Your copy of the Haka Tours brochure will be sent immediately. If it hasn’t arrived, please check your spam and junk folders. Please contact
          us if it hasn’t arrived within the hour. We’re looking forward to helping your build your next adventure.
        </p>
        <br />
        <br />
        <p>
          <Link
            to={PAGES.HOME.PATH}
            className="c-button c-button--primary c-button--wide"
          >
            Back home
          </Link>
        </p>
      </div>
    </section>
  </Layout>
);

export default ThankYouPage;
